<template>
    <vs-table
        ref="table"
        v-model="selected"
        search
        :data="wills"
        :sst="true"
        @search="search"
        @selected="$router.push({ name: 'will', params: { id: $event.id } })"
        @sort="handleSort"
    >
        <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-between"
        >
            <div
                class="flex flex-wrap-revers items-center data-list-btn-container"
            >
                <datepicker
                    placeholder="Defer Date"
                    v-model="deferDate"
                ></datepicker>
            </div>
            <p v-show="searchQuery" class="mr-4 mb-4">
                Search results for '{{ searchQuery }}'
            </p>

            <div>
                <!-- STATUS -->
                <vs-dropdown
                    vs-trigger-click
                    class="cursor-pointer mb-4 mr-4 items-per-page-handler"
                >
                    <div
                        class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                    >
                        <span class="mr-2"> {{ status }} </span>
                        <feather-icon
                            icon="ChevronDownIcon"
                            svgClasses="h-4 w-4"
                        />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item
                            @click="setLimit('To Contact', 'to_contact')"
                        >
                            <span>To Contact </span>
                        </vs-dropdown-item>

                        <vs-dropdown-item
                            divider
                            @click="
                                setLimit('Contact - Admin', 'to_contact_admin')
                            "
                        >
                            <span>Contact - Admin</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item
                            @click="
                                setLimit(
                                    'Contact - Estate Planner',
                                    'to_contact_estate'
                                )
                            "
                        >
                            <span>Contact - Estate Planner</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>

                <!-- ITEMS PER PAGE -->
                <vs-dropdown
                    vs-trigger-click
                    class="cursor-pointer mb-4 mr-4 items-per-page-handler"
                >
                    <div
                        class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                    >
                        <span class="mr-2"> {{ itemsPerPage }} </span>
                        <feather-icon
                            icon="ChevronDownIcon"
                            svgClasses="h-4 w-4"
                        />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item @click="$emit('itemsPerPage', 5)">
                            <span>5</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="$emit('itemsPerPage', 10)">
                            <span>10</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="$emit('itemsPerPage', 20)">
                            <span>20</span>
                        </vs-dropdown-item>
                        <vs-dropdown-item @click="$emit('itemsPerPage', 50)">
                            <span>50</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </div>
        </div>

        <template slot="thead">
            <vs-th sort-key="user__personaldetails__full_name">Name</vs-th>
            <vs-th sort-key="paid_date">Date Paid</vs-th>
            <vs-th sort-key="user__email">Email</vs-th>
            <vs-th></vs-th>
            <vs-th sort-key="coupon_used__broker__company_name"
                >Introducer</vs-th
            >
            <vs-th sort-key="coupon_used__code">Coupon</vs-th>
            <vs-th sort-key="payment_details__will_amount">Paid Amount</vs-th>
        </template>

        <template slot-scope="{ data }">
            <tbody>
                <vs-tr :data="will" :key="index" v-for="(will, index) in data">
                    <vs-td>
                        {{ will.user.details.full_name }}
                    </vs-td>

                    <vs-td>
                        <vx-tooltip
                            :color="timeColour(will.paid_date, 'contact')"
                            title="Paid Date"
                            :text="formatDateAndTime(will.paid_date)"
                            position="bottom"
                        >
                            <span class="flex items-center px-2 py-1 rounded">
                                <div
                                    class="h-3 w-3 rounded-full mr-2"
                                    :class="
                                        'bg-' +
                                        timeColour(will.paid_date, 'contact')
                                    "
                                ></div>
                                {{ timeDifference(will.paid_date, 'contact') }}
                            </span>
                        </vx-tooltip>
                    </vs-td>

                    <vs-td>
                        {{ will.user.email }}
                    </vs-td>

                    <vs-td>
                        <vs-chip
                            v-if="will.checked_contact_client_advice"
                            class="mr-3"
                            >Estate
                        </vs-chip>
                        <vs-chip v-if="will.checked_contact_client_issue"
                            >Admin</vs-chip
                        >
                    </vs-td>

                    <vs-td>
                        {{ introducer(will.coupon_used) }}
                    </vs-td>

                    <vs-td>
                        {{ couponUsed(will.coupon_used) }}
                    </vs-td>
                    <vs-th>
                        <div
                            v-if="
                                will.payment_details &&
                                will.payment_details.will_amount
                            "
                            class="mr-3 mt-3"
                        >
                            <vx-tooltip
                                :text="`Paid £${
                                    will.payment_details.will_amount / 100
                                } for Will`"
                                position="bottom"
                            >
                                <feather-icon
                                    icon="DollarSignIcon"
                                ></feather-icon>
                            </vx-tooltip>
                        </div>
                        <div
                            v-if="
                                will.payment_details &&
                                will.payment_details.printing_amount
                            "
                            class="mr-3 mt-3"
                        >
                            <vx-tooltip
                                text="Paid for Printing"
                                position="bottom"
                            >
                                <feather-icon icon="PrinterIcon"></feather-icon>
                            </vx-tooltip>
                        </div>
                        <div
                            v-if="
                                will.subscription_details &&
                                will.subscription_details.active
                            "
                            class="mr-3 mt-3"
                        >
                            <vx-tooltip
                                text="Active Subscription"
                                position="bottom"
                            >
                                <feather-icon icon="AwardIcon"></feather-icon>
                            </vx-tooltip>
                        </div>

                        <div v-if="will.update" class="mr-3 mt-3">
                            <vx-tooltip text="Update Will" position="bottom">
                                <feather-icon
                                    icon="AlertCircleIcon"
                                ></feather-icon>
                            </vx-tooltip>
                        </div>
                    </vs-th>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'
import Datepicker from 'vuejs-datepicker'

export default {
    name: 'ToContactTable',
    mixins: [textFormat, tableHelpers],
    props: ['data', 'itemsPerPage', 'initialDate'],
    components: {
        Datepicker,
    },
    computed: {
        wills() {
            return this.data.data
        },
        deferDate: {
            get() {
                return this.initialDate
            },
            set(value) {
                this.$emit('deferDate', value.toISOString().slice(0, 10))
            },
        },
    },
    data() {
        return {
            selected: null,
            status: 'To Contact',
            searchQuery: '',
        }
    },
    methods: {
        setLimit(status, value) {
            this.status = status
            this.$emit('limit', value)
        },
        search(value) {
            if (value.length > 3 || value === '') {
                this.searchQuery = value
                this.$emit('search', value)
            }
        },
        handleSort(key, active) {
            this.$emit('sort', key, active)
        },
    },
}
</script>
