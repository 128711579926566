var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vs-table',{ref:"table",attrs:{"search":"","data":_vm.wills,"sst":true},on:{"search":_vm.search,"selected":function($event){return _vm.$router.push({ name: 'will', params: { id: $event.id } })},"sort":_vm.handleSort},scopedSlots:_vm._u([{key:"default",fn:function({ data }){return [_c('tbody',_vm._l((data),function(will,index){return _c('vs-tr',{key:index,attrs:{"data":will}},[_c('vs-td',[_vm._v(" "+_vm._s(will.user.details.full_name)+" ")]),_c('vs-td',[_c('vx-tooltip',{attrs:{"color":_vm.timeColour(will.paid_date, 'contact'),"title":"Paid Date","text":_vm.formatDateAndTime(will.paid_date),"position":"bottom"}},[_c('span',{staticClass:"flex items-center px-2 py-1 rounded"},[_c('div',{staticClass:"h-3 w-3 rounded-full mr-2",class:'bg-' +
                                    _vm.timeColour(will.paid_date, 'contact')}),_vm._v(" "+_vm._s(_vm.timeDifference(will.paid_date, 'contact'))+" ")])])],1),_c('vs-td',[_vm._v(" "+_vm._s(will.user.email)+" ")]),_c('vs-td',[(will.checked_contact_client_advice)?_c('vs-chip',{staticClass:"mr-3"},[_vm._v("Estate ")]):_vm._e(),(will.checked_contact_client_issue)?_c('vs-chip',[_vm._v("Admin")]):_vm._e()],1),_c('vs-td',[_vm._v(" "+_vm._s(_vm.introducer(will.coupon_used))+" ")]),_c('vs-td',[_vm._v(" "+_vm._s(_vm.couponUsed(will.coupon_used))+" ")]),_c('vs-th',[(
                            will.payment_details &&
                            will.payment_details.will_amount
                        )?_c('div',{staticClass:"mr-3 mt-3"},[_c('vx-tooltip',{attrs:{"text":`Paid £${
                                will.payment_details.will_amount / 100
                            } for Will`,"position":"bottom"}},[_c('feather-icon',{attrs:{"icon":"DollarSignIcon"}})],1)],1):_vm._e(),(
                            will.payment_details &&
                            will.payment_details.printing_amount
                        )?_c('div',{staticClass:"mr-3 mt-3"},[_c('vx-tooltip',{attrs:{"text":"Paid for Printing","position":"bottom"}},[_c('feather-icon',{attrs:{"icon":"PrinterIcon"}})],1)],1):_vm._e(),(
                            will.subscription_details &&
                            will.subscription_details.active
                        )?_c('div',{staticClass:"mr-3 mt-3"},[_c('vx-tooltip',{attrs:{"text":"Active Subscription","position":"bottom"}},[_c('feather-icon',{attrs:{"icon":"AwardIcon"}})],1)],1):_vm._e(),(will.update)?_c('div',{staticClass:"mr-3 mt-3"},[_c('vx-tooltip',{attrs:{"text":"Update Will","position":"bottom"}},[_c('feather-icon',{attrs:{"icon":"AlertCircleIcon"}})],1)],1):_vm._e()])],1)}),1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('div',{staticClass:"flex flex-wrap-reverse items-center flex-grow justify-between",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"flex flex-wrap-revers items-center data-list-btn-container"},[_c('datepicker',{attrs:{"placeholder":"Defer Date"},model:{value:(_vm.deferDate),callback:function ($$v) {_vm.deferDate=$$v},expression:"deferDate"}})],1),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchQuery),expression:"searchQuery"}],staticClass:"mr-4 mb-4"},[_vm._v(" Search results for '"+_vm._s(_vm.searchQuery)+"' ")]),_c('div',[_c('vs-dropdown',{staticClass:"cursor-pointer mb-4 mr-4 items-per-page-handler",attrs:{"vs-trigger-click":""}},[_c('div',{staticClass:"p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"},[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.status)+" ")]),_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","svgClasses":"h-4 w-4"}})],1),_c('vs-dropdown-menu',[_c('vs-dropdown-item',{on:{"click":function($event){return _vm.setLimit('To Contact', 'to_contact')}}},[_c('span',[_vm._v("To Contact ")])]),_c('vs-dropdown-item',{attrs:{"divider":""},on:{"click":function($event){return _vm.setLimit('Contact - Admin', 'to_contact_admin')}}},[_c('span',[_vm._v("Contact - Admin")])]),_c('vs-dropdown-item',{on:{"click":function($event){return _vm.setLimit(
                                'Contact - Estate Planner',
                                'to_contact_estate'
                            )}}},[_c('span',[_vm._v("Contact - Estate Planner")])])],1)],1),_c('vs-dropdown',{staticClass:"cursor-pointer mb-4 mr-4 items-per-page-handler",attrs:{"vs-trigger-click":""}},[_c('div',{staticClass:"p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"},[_c('span',{staticClass:"mr-2"},[_vm._v(" "+_vm._s(_vm.itemsPerPage)+" ")]),_c('feather-icon',{attrs:{"icon":"ChevronDownIcon","svgClasses":"h-4 w-4"}})],1),_c('vs-dropdown-menu',[_c('vs-dropdown-item',{on:{"click":function($event){return _vm.$emit('itemsPerPage', 5)}}},[_c('span',[_vm._v("5")])]),_c('vs-dropdown-item',{on:{"click":function($event){return _vm.$emit('itemsPerPage', 10)}}},[_c('span',[_vm._v("10")])]),_c('vs-dropdown-item',{on:{"click":function($event){return _vm.$emit('itemsPerPage', 20)}}},[_c('span',[_vm._v("20")])]),_c('vs-dropdown-item',{on:{"click":function($event){return _vm.$emit('itemsPerPage', 50)}}},[_c('span',[_vm._v("50")])])],1)],1)],1)]),_c('template',{slot:"thead"},[_c('vs-th',{attrs:{"sort-key":"user__personaldetails__full_name"}},[_vm._v("Name")]),_c('vs-th',{attrs:{"sort-key":"paid_date"}},[_vm._v("Date Paid")]),_c('vs-th',{attrs:{"sort-key":"user__email"}},[_vm._v("Email")]),_c('vs-th'),_c('vs-th',{attrs:{"sort-key":"coupon_used__broker__company_name"}},[_vm._v("Introducer")]),_c('vs-th',{attrs:{"sort-key":"coupon_used__code"}},[_vm._v("Coupon")]),_c('vs-th',{attrs:{"sort-key":"payment_details__will_amount"}},[_vm._v("Paid Amount")])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }